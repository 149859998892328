/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent, useContext, useState } from 'react'
import * as S from './styles'
import {
  InputStyled,
  InputBox,
  ShowPassword,
  Check,
  EntryButton
} from 'src/styles'
import { AuthContext } from 'src/contexts/Auth/AuthContext'
import { useNavigate } from 'react-router-dom'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import Onboarding from 'src/components/Onboarding'

const Register = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [showOnboarding, setShowOnboarding] = useState(false)

  const [notification, setNotification] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error' | 'info' | 'warning'
  }>({
    open: false,
    message: '',
    severity: 'success'
  })

  const handleCloseNotification = (
    _event: Event | SyntheticEvent<unknown, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setNotification({ ...notification, open: false })
  }

  const handleViewPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRegister = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (email && password && name) {
      if (password === confirmPassword) {
        setIsLoading(true)
        try {
          const success = await auth.signup(name, email, password)
          if (success) {
            setNotification({
              open: true,
              message: 'Usuário cadastrado com sucesso.',
              severity: 'success'
            })
            setShowOnboarding(true)
            navigate('/dashboard')
          }
        } catch (error) {
          if ((error as any).code === 'auth/email-already-in-use') {
            setNotification({
              open: true,
              message: 'Email já cadastrado',
              severity: 'info'
            })
          } else if ((error as any).code === 'auth/invalid-email') {
            setNotification({
              open: true,
              message: 'Email inválido',
              severity: 'info'
            })
          } else {
            console.log(error)
            setNotification({
              open: true,
              message: 'Erro ao cadastrar',
              severity: 'error'
            })
          }
        } finally {
          setIsLoading(false)
        }
      } else {
        setNotification({
          open: true,
          message: 'Senhas não conferem',
          severity: 'warning'
        })
      }
    }
  }

  return (
    <>
      <S.Back to="/login">Voltar</S.Back>
      <S.Container>
        {isLoading ? (
          <div>Carregando...</div>
        ) : (
          <S.FormRegister>
            <S.Register>Registrar-se</S.Register>
            <InputBox>
              <InputStyled
                id="name"
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Nome Completo"
                autoComplete="name"
                required
              />
            </InputBox>
            <InputBox>
              <InputStyled
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                autoComplete="off"
                required
              />
            </InputBox>
            <InputBox>
              <InputStyled
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                type={showPassword ? 'text' : 'password'}
                placeholder="Cadastre uma senha"
              />
            </InputBox>
            <InputBox>
              <InputStyled
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                type={showPassword ? 'text' : 'password'}
                placeholder="Confirme a senha"
              />
            </InputBox>
            <ShowPassword>
              <Check
                checked={showPassword}
                onChange={handleViewPassword}
                type="checkbox"
                id="check"
              />
              <label htmlFor="check">Mostrar Senha</label>
            </ShowPassword>
            <EntryButton onClick={handleRegister} type="submit">
              Salvar Dados
            </EntryButton>
          </S.FormRegister>
        )}
      </S.Container>
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={(event, reason) => handleCloseNotification(event, reason)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() =>
            handleCloseNotification(
              {} as React.SyntheticEvent<unknown, Event>,
              ''
            )
          }
          severity={notification.severity}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
      {showOnboarding && <Onboarding />}
    </>
  )
}

export default Register
