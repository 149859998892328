import Header from 'src/components/Header'
import Schedule from 'src/components/Schedule'
import * as S from './styles'
import { AutoCompleteName } from 'src/components/AutoCompleteName'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'
import { SessionProvider } from 'src/contexts/Sessions/SessionProvider'
import { SessionClientProvider } from 'src/contexts/SessionClient/SessionClientProvider'
import { useSetupContext } from '../../contexts/Setups/SetupContext'
import { SetupProvider } from '../../contexts/Setups/SetupProvider'
import { DateProvider } from 'src/contexts/Date/DateContext'
import { useContext, useEffect, useState } from 'react'
import MembersContext from 'src/contexts/Members/MembersContext'

type SchedulePageProps = {
  aluno?: string
  id?: string
  showHeader?: boolean
}

const SessionComponentWithSetup = () => {
  const { setups } = useSetupContext()

  return (
    <SessionProvider setups={setups}>
      <Schedule />
    </SessionProvider>
  )
}

const SchedulePage = ({ aluno, showHeader = true }: SchedulePageProps) => {
  const { members, setSelectedMemberId } = useContext(MembersContext)
  const [alunoId, setAlunoId] = useState<string | null>(null)

  useEffect(() => {
    const foundMember = members.find((member) => member.name === aluno)
    if (foundMember) {
      setAlunoId(foundMember.id)
      setSelectedMemberId(foundMember.id)
    } else {
      console.error('Aluno não encontrado.')
    }
  }, [aluno, members, setSelectedMemberId])

  return (
    <>
      {showHeader && <Header />}
      <S.Container>
        <MemberProvider>
          <AutoCompleteName initialName={aluno} initialId={alunoId} />
          <DateProvider>
            <SetupProvider>
              <SessionClientProvider>
                <SessionComponentWithSetup />
              </SessionClientProvider>
            </SetupProvider>
          </DateProvider>
        </MemberProvider>
      </S.Container>
    </>
  )
}

export default SchedulePage
