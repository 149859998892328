import { createContext } from 'react'
import Session from 'src/Types/sessions'

interface SessionContextProps {
  sessions: Session[]
  setSessionId: (id: string | null) => void
  fetchSessions: (dates?: string[]) => Promise<void>
}

const SessionContext = createContext<SessionContextProps>({
  sessions: [],
  setSessionId: () => {},
  fetchSessions: async () => {}
})

export default SessionContext
