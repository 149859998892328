import { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSetupContext } from 'src/contexts/Setups/SetupContext'
import * as S from './styles'
import { Setup } from 'src/Types/setup'

interface FormValues {
  availability: Record<string, boolean>
  timeSlots: Record<string, string[]>
}

const SetupCard: React.FC = () => {
  const { addSetup } = useSetupContext()

  const { control, handleSubmit, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      availability: {
        Segunda: false,
        Terça: false,
        Quarta: false,
        Quinta: false,
        Sexta: false,
        Sábado: false,
        Domingo: false
      },
      timeSlots: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: [],
        Sábado: [],
        Domingo: []
      }
    }
  })

  const daysOfWeek = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo'
  ] as const

  const timeSlots = [
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00'
  ]

  const [localTimeSlots, setLocalTimeSlots] = useState<
    Record<string, string[]>
  >({
    Segunda: [],
    Terça: [],
    Quarta: [],
    Quinta: [],
    Sexta: [],
    Sábado: [],
    Domingo: []
  })

  const [error, setError] = useState<string | null>(null)

  const addSlot = (day: string) => {
    setLocalTimeSlots((prev) => ({
      ...prev,
      [day]: [...prev[day], '']
    }))
  }

  const removeSlot = (day: string, index: number) => {
    setLocalTimeSlots((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index)
    }))
  }

  const handleTimeSlotChange = (day: string, index: number, value: string) => {
    setLocalTimeSlots((prev) => {
      const newSlots = [...prev[day]]
      newSlots[index] = value
      return {
        ...prev,
        [day]: newSlots
      }
    })
  }

  const availability = useWatch({ control, name: 'availability' })

  const handleAvailabilityChange = (day: string, checked: boolean) => {
    setValue(`availability.${day}`, checked)
    if (!checked) {
      setLocalTimeSlots((prev) => ({ ...prev, [day]: [] }))
      setValue(`timeSlots.${day}`, [])
    }
  }

  const onSubmit = async () => {
    Object.keys(localTimeSlots).forEach((day) => {
      setValue(`timeSlots.${day}`, localTimeSlots[day])
    })
    const values = getValues()

    const hasEmptySlots = Object.keys(values.timeSlots).some((day) =>
      values.timeSlots[day].some((slot) => slot === '')
    )

    const allSlotsEmpty = Object.keys(values.timeSlots).every(
      (day) => values.timeSlots[day].length === 0
    )

    if (hasEmptySlots) {
      setError(
        'Por favor, selecione pelo menos um horário. Caso contrário, exclua.'
      )
    } else if (allSlotsEmpty) {
      setError('Pelo menos um dia da semana deve ter um horário escolhido.')
    } else {
      setError(null)
      const setup: Setup = {
        duration: 60,
        max_participants: 5,
        blueprint: {
          monday: values.timeSlots.Segunda,
          tuesday: values.timeSlots.Terça,
          wednesday: values.timeSlots.Quarta,
          thursday: values.timeSlots.Quinta,
          friday: values.timeSlots.Sexta,
          saturday: values.timeSlots.Sábado,
          sunday: values.timeSlots.Domingo
        }
      }
      try {
        await addSetup(setup)
        window.location.reload()
      } catch (error) {
        console.error('Failed to add setup', error)
        setError('Houve um erro ao adicionar a configuração.')
      }
    }
  }

  return (
    <S.SetupContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
        <S.DayContainer>
          {daysOfWeek.map((day) => (
            <div key={day}>
              <Controller
                name={`availability.${day}`}
                control={control}
                render={({ field }) => (
                  <S.Label>
                    <S.Checkbox
                      id={day}
                      type="checkbox"
                      checked={field.value}
                      onChange={(e) =>
                        handleAvailabilityChange(day, e.target.checked)
                      }
                    />
                    {day}
                  </S.Label>
                )}
              />
              {availability[day] && (
                <div>
                  {localTimeSlots[day].map((slot, index) => (
                    <div key={index}>
                      <S.Options
                        id="timeSlots"
                        value={slot}
                        onChange={(e) =>
                          handleTimeSlotChange(day, index, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Selecione
                        </option>
                        {timeSlots.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </S.Options>
                      <S.ControllerButton
                        type="button"
                        onClick={() => removeSlot(day, index)}
                      >
                        x
                      </S.ControllerButton>
                    </div>
                  ))}
                  <div>
                    <S.ControllerButton
                      type="button"
                      onClick={() => addSlot(day)}
                    >
                      +
                    </S.ControllerButton>
                  </div>
                </div>
              )}
            </div>
          ))}
        </S.DayContainer>
        <S.Submit>Salvar</S.Submit>
      </form>
    </S.SetupContainer>
  )
}

export default SetupCard
