import * as S from './styles'

type SetupModalProps = {
  isOpen: boolean
  setModalOpen: () => void
  children: React.ReactNode
}

export default function SetupModal({
  isOpen,
  children,
  setModalOpen
}: SetupModalProps) {
  if (isOpen) {
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          {children}
          <S.CloseButton onClick={setModalOpen}>Fechar</S.CloseButton>
        </S.ModalContainer>
      </S.ModalBackground>
    )
  }
  return null
}
