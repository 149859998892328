// AutoCompleteName.tsx
import { useEffect, useState } from 'react'
import * as S from './styles'
import { useMemberContext } from '../../contexts/Members/MembersContext'
import { Member } from 'src/Types/members'

type AutoCompleteNameProps = {
  initialName?: string
  initialId?: string | null
}

export const AutoCompleteName: React.FC<AutoCompleteNameProps> = ({
  initialName = '',
  initialId = null
}: AutoCompleteNameProps) => {
  const { members, setSelectedMemberId } = useMemberContext()
  const [inputValue, setInputValue] = useState(initialName)
  const [filterSearch, setFilterSearch] = useState<Member[]>([])

  useEffect(() => {
    if (initialId) {
      setSelectedMemberId(initialId)
    }
  }, [initialId, setSelectedMemberId])

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    const newFilter = members.filter((member) =>
      member.name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilterSearch(newFilter)
  }

  function handleClickAutoComplete(member: Member) {
    setInputValue(member.name)
    setFilterSearch([])
    setSelectedMemberId(member.id)
  }

  function clearText() {
    setInputValue('')
    setFilterSearch([])
  }

  return (
    <>
      <S.SearchContainer>
        <S.SearchInput>
          <S.InputName
            id="inputName"
            value={inputValue}
            onChange={handleFilter}
            type="text"
            placeholder="Nome"
          />
          {inputValue !== '' ? <S.StyledIcon onClick={clearText} /> : null}
        </S.SearchInput>
        {filterSearch.length !== 0 && (
          <S.DataResult>
            {filterSearch
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((member) => (
                <S.DataItem
                  key={member.id}
                  onClick={() => handleClickAutoComplete(member)}
                >
                  <p>{member.name}</p>
                </S.DataItem>
              ))}
          </S.DataResult>
        )}
      </S.SearchContainer>
    </>
  )
}
