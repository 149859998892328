import { createContext } from 'react'
import { SessionClient } from 'src/Types/session_client'

interface SessionClientContextProps {
  sessionClient: SessionClient
  addSessionClient: (sessionClient: SessionClient) => void
}

const SessionClientContext = createContext<SessionClientContextProps>({
  sessionClient: {
    id: '',
    client_id: '',
    session_id: ''
  },
  addSessionClient: () => {}
})

export default SessionClientContext
