import variables from 'src/styles/variables'
import styled from 'styled-components'

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  @media (max-width: 768px) {
    position: absolute;
  }
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 150px;
  background: ${variables.white};
  border-radius: 10px;
  color: #000;
  max-height: 90vh; /* Limita a altura do modal para evitar que ele ultrapasse a tela */
  overflow-y: auto; /* Adiciona rolagem vertical se necessário */

  @media (max-width: 768px) {
    width: 300px;
    padding: 30px;
    top: 50px; /* Ajuste a posição para começar mais abaixo em telas menores */
    transform: translate(-50%, 0); /* Remove o deslocamento vertical */
    max-height: calc(
      100vh - 100px
    ); /* Ajusta o tamanho máximo do modal em telas menores */
  }
`

export const CloseButton = styled.button`
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 10px);
  background: none;
  border: 1px solid ${variables.purple};
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
  padding: 15px;
  cursor: pointer;
  color: ${variables.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;

  &:hover {
    color: ${variables.white};
    border: 1px solid ${variables.darkGray};
    background-color: ${variables.gray};
  }

  @media (max-width: 768px) {
  }
`
