import { useState } from 'react'
import { useMemberContext } from 'src/contexts/Members/MembersContext'
import Button from '../Button'
import * as S from './styles'

const AddMember: React.FC = () => {
  const { addMember } = useMemberContext()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const handleAddMember = (event: React.FormEvent) => {
    event.preventDefault()

    if (name.trim() === '' || phone.trim() === '') {
      console.warn('Nome ou telefone vazio')
      return
    }

    const newMember = {
      id: crypto.randomUUID(),
      name,
      phone
    }
    addMember(newMember)
    setName('')
    setPhone('')
  }

  return (
    <>
      <S.CustomBoxType>
        <h3>Cadastro de Alunos</h3>
        <form onSubmit={handleAddMember}>
          <S.InputName
            id="name"
            autoComplete="off"
            type="text"
            placeholder="Nome Completo"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <S.InputPhone
            id="phone"
            autoComplete="off"
            type="phone"
            placeholder="Número do Telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <Button>Cadastrar Aluno</Button>
        </form>
      </S.CustomBoxType>
    </>
  )
}

export default AddMember
