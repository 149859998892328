import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import SessionContext from '../Sessions/SessionContext'
interface CardContextProps {
  card: {
    time: string
    class: string
    classDetails?: { id: string; aluno: string }[] | string
  }[]
}

const CardContext = createContext<CardContextProps | null>(null)

const CardProvider = ({ children }: { children: ReactNode }) => {
  const { sessions } = useContext(SessionContext)
  const [card, setCard] = useState<CardContextProps['card']>([])
  const [isLoading, setIsLoading] = useState(true)
  const [noSessionsMessage, setNoSessionsMessage] = useState(false)

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      const newCardData = sessions

        .map((session) => ({
          time: session.name,
          class: ` `,
          classDetails: session.participants.length
            ? session.participants.map((participant) => ({
                id: participant.id,
                aluno: participant.name
              }))
            : 'Sem alunos cadastrados'
        }))

        .sort(
          (a, b) =>
            new Date(`1970-01-01T${a.time}:00Z`).getTime() -
            new Date(`1970-01-01T${b.time}:00Z`).getTime()
        )
      setCard(newCardData)
      setNoSessionsMessage(false)
    } else {
      setCard([])
      setNoSessionsMessage(true)
    }
    setIsLoading(false)
  }, [sessions])

  if (isLoading) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          gridColumn: 'span 3'
        }}
      >
        <h4>Carregando...</h4>
      </div>
    )
  }

  return (
    <CardContext.Provider value={{ card }}>
      {noSessionsMessage && (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            gridColumn: 'span 3'
          }}
        >
          <h4>Não existem sessões cadastradas</h4>
        </div>
      )}
      {children}
    </CardContext.Provider>
  )
}

export { CardProvider, CardContext }
