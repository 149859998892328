import { useContext, useEffect, useState } from 'react'
import { Member } from 'src/Types/members'
import MembersContext from './MembersContext'
import Api from 'src/providers/Api'
import { AuthContext } from '../Auth/AuthContext'
import { formatPhone } from 'src/utils/formatPhone'

interface MembersProviderProps {
  children: React.ReactNode
}

export const MemberProvider: React.FC<MembersProviderProps> = ({
  children
}: MembersProviderProps) => {
  const auth = useContext(AuthContext)
  const [members, setMembers] = useState<Member[]>([])
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null)

  const accessToken = auth.accessToken

  const fetchMembers = async () => {
    await Api.get('/clients', {
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setMembers(response.data.data)
        } else {
          console.error('Response data is not an array:', response.data)
          setMembers([])
        }
      })
      .catch((error) => {
        console.error('Failed to fetch members', error)
        setMembers([])
      })
  }

  useEffect(() => {
    fetchMembers()
  }, [])

  const addMember = async (member: Member) => {
    const formattedMember = {
      ...member,
      phone: formatPhone(member.phone)
    }

    if (formattedMember.name === '' || formattedMember.phone === '') {
      return
    }

    await Api.post('/clients', formattedMember, {
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.status === 201) {
        setMembers((prevMembers) => [...prevMembers])
        fetchMembers()
      } else {
        console.error('Failed to add member', response.statusText)
      }
    })
  }

  const removeMember = async (id: string) => {
    await Api.delete(`/clients/${id}`, {
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.status === 204) {
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== id)
        )
        fetchMembers()
      }
    })
  }

  // const nomeDoId = members.find((member) => member.id === selectedMemberId)

  // console.log('nomeDoId:', nomeDoId?.id)

  return (
    <MembersContext.Provider
      value={{
        members,
        selectedMemberId,
        setSelectedMemberId,
        fetchMembers,
        addMember,
        removeMember
      }}
    >
      {children}
    </MembersContext.Provider>
  )
}
