import { useState } from 'react'
import ReactJoyride, { Step } from 'react-joyride'
import variables from 'src/styles/variables'
const steps: Step[] = [
  {
    target: '#dashboard',
    content: (
      <div>
        <p>
          Aqui é o início, onde você encontra os horários disponíveis e pode
          navegar pelas datas. Clicando em Detalhes, vocè poderá ver os alunos
          agendados e também poderá refazer os agendamentos, caso necessário.
        </p>
      </div>
    ),
    disableBeacon: true
  },
  {
    target: '#setup',
    content:
      'Aqui você pode cadastrar seus dias e horários de aula. Caso seja necessário, pode alterá-los também.'
  },
  {
    target: '#schedule',
    content: 'Aqui você irá agendar seus alunos nas aulas disponíveis.'
  },
  {
    target: '#members',
    content:
      'Cadastre seus alunos e edite suas informações. Caso necessário, pode excluir os dados também'
  }
]

const Onboarding = () => {
  const [run, setRun] = useState(true)

  return (
    <ReactJoyride
      steps={steps}
      run={run}
      continuous
      hideCloseButton
      disableOverlayClose
      disableCloseOnEsc
      styles={{
        options: { zIndex: 10000, primaryColor: `${variables.purple}` }
      }}
      callback={(data) => {
        if (data.status === 'finished' || data.status === 'skipped') {
          setRun(false)
        }
      }}
    />
  )
}

export default Onboarding
