import { IoIosCloseCircleOutline } from 'react-icons/io'
import variables from 'src/styles/variables'
import styled from 'styled-components'
import { ScheduleContainer } from 'src/styles'

export const SearchContainer = styled(ScheduleContainer)`
  width: 100%;
`

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50px;
  padding: 0 24px;
  border-radius: 8px;
  gap: 24px;
  background-color: ${variables.whiteSmoke};
  border: 1px solid ${variables.gray};
`

export const InputName = styled.input`
  border: 0;
  outline: 0;
  background: none;
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`

export const DataResult = styled.div`
  margin-top: 56px;
  position: absolute;
  background-color: ${variables.gray};
  border-radius: 5px;
  max-height: 300px;
  width: 450px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 300px;
  }
`

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e9e9e9;
    font-weight: 700;
    text-decoration: underline;
  }
`

export const StyledIcon = styled(IoIosCloseCircleOutline)`
  color: ${variables.darkGray};
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`
