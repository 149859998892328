import { useState } from 'react'
import { useSetupContext } from 'src/contexts/Setups/SetupContext'
import * as S from './styles'
import SetupModal from '../SetupModal'
import EditSetupCard from '../EditSetupCard'

const SetupItens: React.FC = () => {
  const { setups } = useSetupContext()
  const [openModal, setOpenModal] = useState(false)

  const daysOfWeekOrder = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ] as const

  const daysOfWeekLabels: Record<(typeof daysOfWeekOrder)[number], string> = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo'
  }

  return (
    <S.SetupContainer>
      {setups.length > 0 ? (
        setups.map((setup, index) => (
          <div key={index}>
            <S.InformationContainer>
              <div>
                <h4>Duração da Aula: </h4>
                <p>{setup.duration} minutos</p>
              </div>
              <div>
                <h4>Número de Alunos: </h4>
                <p>{setup.max_participants}</p>
              </div>
            </S.InformationContainer>
            <h3>Horários Cadastrados:</h3>
            <S.Hours>
              {daysOfWeekOrder.map((day) => (
                <div key={day}>
                  <h5>{daysOfWeekLabels[day]}:</h5>
                  {setup.blueprint?.[day]?.length > 0 ? (
                    setup.blueprint[day].map((time, timeIndex) => (
                      <span key={timeIndex}>{time}</span>
                    ))
                  ) : (
                    <span></span>
                  )}
                </div>
              ))}
            </S.Hours>
          </div>
        ))
      ) : (
        <p></p>
      )}

      <S.DivButton>
        <button onClick={() => setOpenModal(true)}>Editar</button>
      </S.DivButton>
      <SetupModal
        isOpen={openModal}
        setModalOpen={() => setOpenModal(!openModal)}
      >
        <EditSetupCard />
      </SetupModal>
    </S.SetupContainer>
  )
}

export default SetupItens
