import { useContext } from 'react'
import { AuthContext } from '../Auth/AuthContext'
import Api from 'src/providers/Api'
import SessionClientContext from './SessionClientContext'
import { SessionClient } from 'src/Types/session_client'
interface SessionClientProps {
  children: React.ReactNode
}

export const SessionClientProvider: React.FC<SessionClientProps> = ({
  children
}: SessionClientProps) => {
  const auth = useContext(AuthContext)

  const accessToken = auth.accessToken

  const addSessionClient = async (sessionClient: SessionClient) => {
    await Api.post(
      '/sessions/schedule',
      {
        id: sessionClient.id,
        client_id: sessionClient.client_id,
        session_id: sessionClient.session_id
      },
      {
        headers: {
          Authorization: `${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
  }

  const sessionClient = {
    id: '',
    client_id: '',
    session_id: ''
  }

  return (
    <SessionClientContext.Provider value={{ sessionClient, addSessionClient }}>
      {children}
    </SessionClientContext.Provider>
  )
}
