import Header from 'src/components/Header'
import AddMember from 'src/components/AddMember'
import MembersList from 'src/components/MembersList'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Members = () => {
  return (
    <>
      <Header />
      <Container>
        <MemberProvider>
          <AddMember />
          <MembersList />
        </MemberProvider>
      </Container>
    </>
  )
}

export default Members
